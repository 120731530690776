import TabView from '../../global/components/TabView/TabView';

const Home = () => {
	return (
		<>
			<TabView />
		</>
	);
};

export default Home;
