import Collapsible from '../../components/Collapsible/CollapsibleContainer';

const Faq = () => {
	return (
		<>
			<section>
				<Collapsible />
			</section>
		</>
	);
};

export default Faq;
